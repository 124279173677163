<template>
  <loading-spinner v-if="loading"></loading-spinner>
  <div class="bot-initialization-dialog" v-else>
    <!-- <div v-if="recipe === 'store'"> -->
    <div>
      <div
        class="wizard-container"
        v-for="(section, index) in sectionsOfRecipe"
        :key="index"
        v-if="step === index"
      >
        <p id="chatbot-sectionTitle" style="font-size: 22px;">{{ section.title }}</p>

        <div>
          <p class="section-description">{{ section.body }}</p>
          <ul v-if="section.list">
            <li v-for="(item, index) in section.list" :key="index">
              <p>
                <a :href="item.url">{{ item.title }}</a>
              </p>
            </li>
          </ul>
        </div>

        <div v-if="section.images" class="images-examples">
          <div v-for="(image, index) in section.images" :key="index">
            <img :src="image.img" alt="" />
            <p style="font-weight: bold; direction: rtl;">{{ image.text }}</p>
          </div>
        </div>
        <div v-else-if="section.inputs">
          <inputField
            v-for="(property_, index) in section.inputs"
            :key="index"
            :inputFor="property_.inputFor"
            :inputType="property_.type"
            :optionsUrl="property_.optionsUrl"
            :title="property_.title"
            :inputIsRequired="property_.isRequired"
            :inputIsInteger="property_.isInteger"
            :maxlength="property_.maxlength"
            :value="settings[property_.name]"
            v-model="$v.settings.$model[property_.name]"
          >
          </inputField>

          <div class="mt-12">
            <p class="text-xl text-center font-semibold">بطاقة الترحيب</p>
            <p class="text-center mb-6 mt-2">البطاقة التي تظهر عند بدء المحادثة مع المستخدم</p>
            <p class="text-center mb-6 mt-2 rtl">
              {{ listTemplateHeader }}
            </p>
            <div class="flex justify-center items-center content-center">
              <listTemplate
                :image="settings.image"
                :startText="settings.mainMenuButtons.startButton"
                :contactText="settings.mainMenuButtons.contactUsButton"
                @START_BUTTON_CHANGED="
                  startButtonName => {
                    $v.settings.$model.mainMenuButtons.startButton = startButtonName;
                  }
                "
                @CONTACT_BUTTON_CHANGED="
                  contactButtonName => {
                    $v.settings.$model.mainMenuButtons.contactUsButton = contactButtonName;
                  }
                "
                @IMAGE_CHANGED="
                  image => {
                    $v.settings.$model.image = image;
                  }
                "
              ></listTemplate>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wizard-control">
      <button
        class="mujeeb-button green"
        @click="nextSection"
        v-if="step < sectionsOfRecipe.length"
      >
        {{ step === sectionsOfRecipe.length - 1 ? "تفعيل" : "التالي" }}
      </button>
      <button class="mujeeb-button" @click="previousSection" v-if="step >= 0">رجوع</button>
    </div>
  </div>
</template>

<script>
import inputField from "../input/GenericInputField";
import listTemplate from "../chatbot/utilityComponents/listTemplate";
import moment from "moment";
/* eslint-disable prettier/prettier */
const isInteger = condition => value => {
  return condition ? /^\d+$/.test(value) || /[\u0660-\u0669]/g.test(value) : true;
};

const isRequired = (condition, inputType) => value => {
  return condition ? (inputType === "tags" ? value.length !== 0 : value !== "") : true;
};

export default {
  props: {
    recipe: {
      type: String,
      required: true
    }
  },
  components: {
    inputField,
    listTemplate
  },
  data() {
    const contatButtonExists = this.$getAtPath(
      ["contact", "initiated"],
      this.$store.getters["page/services"]
    );
    const startButton = this.$store.getters["recipesConfig/welcomeCard_2"](this.recipe).buttons[0];
    const mainMenuButtons = { startButton: startButton };
    if (contatButtonExists) {
      mainMenuButtons.contactUsButton = "تواصل معنا";
    }
    return {
      step: 0,
      loading: false,
      settings: {
        aspectRatio: "square",
        currency: "SYP",
        image: this.$store.getters["page/picture"],
        mainMenuButtons: mainMenuButtons
      },
      recipes: {
        store: [
          {
            title: "قالب المتجر",
            body: `يمكنكم مع متجر مجيب عرض منتجاتكم على زبائنكم في مكان واحد، وتوفير كافة التفاصيل والصور الخاصة بأي منتج لكل زبون يسأل عنها، بشكل فوري وآلي تماماً، دون أن يحتاج الزبون لانتظار الجواب.`,
            // images: ['https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/electronics.gif', 'https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/shoes.gif']
            images: [
              {
                text: "الكترونيات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/Artboard%202.png"
              },
              {
                text: "ألبسة",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/Artboard%201.png"
              },
              {
                text: "أحذية",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/Artboard%203.png"
              }
            ]
          },
          {
            title: "قالب المتجر",
            body: `من خلال تعبئة معلومات المنتجات لمرة واحدة فقط، يستطيع الزبون أن يتصفحها من على المسنجر الخاص بصفحتكم، ليجدها ضمن واجهة جذابة مصنفة مع جميع التفاصيل، وسيقوم مجيب بتحويل الزبائن المهتمين بالشراء ليتواصلوا معكم بشكل مباشر.`,
            images: [
              {
                text: "إضافة منتجات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/items.png"
              },
              {
                text: "تعديل الخصائص",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/settings.png"
              },
              {
                text: "الحصول على إحصائيات  للمنتجات والمشاهدات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/insights.png"
              }
            ]
          },
          {
            title: "قبل البدء",
            body: `قبل البدء يمكنك دوما وفي أي وقت مشاهدة المقاطع التعليمية حول كيفية تفعيل  Mujeeb shop وكيفية إدارة المنتجات والفئات داخل المتجر باتباع هذه الخطوات وفي أي وقت`,
            images: [
              {
                text: `أولاً: اختر من القائمة خيار مساعدة`,
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/01.png"
              },
              {
                text: ' ثانيا: ستظهر لك قائمة الخدمات، اضغط على "متجر مجيب"',
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/02.png"
              },
              {
                text:
                  "ثالثا: اختر قالب المتجر لتشاهد الفيديوهات التعليمية حول كيفية التفعيل وإدارة المنتجات والفئات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/03.png"
              }
            ]
          },
          {
            title: "تخصيص",
            body: `خصص متجرك قبل الإنشاء`,
            inputs: [
              {
                type: "select",
                title: "أبعاد بطاقة إظهار الطبق",
                isRequired: true,
                group: 1,
                name: "aspectRatio",
                inputFor: "aspectRatio",
                default: "square"
              },
              {
                type: "select",
                title: "اسم العملة المستخدم للإظهار",
                isRequired: true,
                group: 3,
                name: "currency",
                inputFor: "currency",
                default: "SYP"
              }
            ]
          }
        ],
        services: [
          {
            title: "قالب الخدمات",
            body: `يمكنكم مع متجر مجيب عرض خدماتكم على زبائنكم في مكان واحد، وتوفير كافة التفاصيل والصور الخاصة بأي خدمة لكل زبون يسأل عنها، بشكل فوري وآلي تماماً، دون أن يحتاج الزائر لانتظار الجواب.`,
            // images: ['https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/electronics.gif', 'https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/shoes.gif']
            images: [
              {
                text: "دورات تعليمية",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/services-1.webp"
              },
              {
                text: "مراكز وعيادات طبية",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/services-2.webp"
              },
              {
                text: "دورات تخصصية",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/services-3.webp"
              }
            ]
          },
          {
            title: "قالب الخدمات",
            body: `من خلال تعبئة معلومات الخدمات لمرة واحدة فقط، يستطيع الزائر أن يتصفحها من على المسنجر الخاص بصفحتكم، ليجدها ضمن واجهة جذابة مصنفة مع جميع التفاصيل، وسيقوم مجيب بتحويل الزوار المهتمين ليتواصلوا معكم بشكل مباشر.`,
            images: [
              {
                text: "إضافة الخدمات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/items-services.png"
              },
              {
                text: "تعديل الخصائص",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/settings-services.png"
              },
              {
                text: "الحصول على إحصائيات  للخدمات والمشاهدات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/insights.png"
              }
            ]
          },
          {
            title: "قبل البدء",
            body: `قبل البدء يمكنك دوما وفي أي وقت مشاهدة المقاطع التعليمية حول كيفية تفعيل  Mujeeb shop وكيفية إدارة الخدمات والفئات داخل المتجر باتباع هذه الخطوات وفي أي وقت`,
            images: [
              {
                text: `أولاً: اختر من القائمة خيار مساعدة`,
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/01.png"
              },
              {
                text: ' ثانيا: ستظهر لك قائمة الخدمات، اضغط على "متجر مجيب"',
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/02.png"
              },
              {
                text:
                  "ثالثا: اختر قالب المتجر لتشاهد الفيديوهات التعليمية حول كيفية التفعيل وإدارة المنتجات والفئات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/03.png"
              }
            ]
          },
          {
            title: "تخصيص",
            body: `خصص متجرك قبل الإنشاء`,
            inputs: [
              {
                type: "select",
                title: "أبعاد بطاقة إظهار الطبق",
                isRequired: true,
                group: 1,
                name: "aspectRatio",
                inputFor: "aspectRatio",
                default: "square"
              },
              {
                type: "select",
                title: "اسم العملة المستخدم للإظهار",
                isRequired: true,
                group: 3,
                name: "currency",
                inputFor: "currency",
                default: "SYP"
              }
            ]
          }
        ],
        restaurant: [
          {
            title: "قالب المطعم",
            body: `يمكنكم مع متجر مجيب عرض الأطباق والماكولات على زبائنكم في مكان واحد، وتوفير كافة التفاصيل عن المكونات والأسعار، بشكل فوري وآلي تماماً، دون أن يحتاج الزائر لانتظار الجواب.`,
            // images: ['https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/electronics.gif', 'https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/shoes.gif']
            images: [
              {
                text: "اعرض قائمة الحلويات لدى مطعمك",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/restaurants_1.webp"
              },
              {
                text: "أو قائمة الساندويتش والمأكولات المتنوعة",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/restaurants_2.webp"
              }
            ]
          },
          {
            title: "قالب المطعم",
            body: `من خلال تعبئة معلومات الطبق أو الساندويتش لمرة واحدة فقط، يستطيع الزائر أن يتصفحها من على المسنجر الخاص بصفحتكم، ليجدها ضمن واجهة جذابة مصنفة مع جميع التفاصيل، وسيقوم مجيب بتحويل الزوار المهتمين ليتواصلوا معكم بشكل مباشر.`,
            images: [
              {
                text: "إضافة أطباق",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/items-services.png"
              },
              {
                text: "تعديل الخصائص",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/settings-services.png"
              },
              {
                text: "الحصول على إحصائيات  عن مشاهدات الأطباق",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/insights.png"
              }
            ]
          },
          {
            title: "قبل البدء",
            body: `قبل البدء يمكنك دوما وفي أي وقت مشاهدة المقاطع التعليمية حول كيفية تفعيل  Mujeeb shop وكيفية إدارة الأطباق والفئات داخل المتجر باتباع هذه الخطوات وفي أي وقت`,
            images: [
              {
                text: `أولاً: اختر من القائمة خيار مساعدة`,
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/01.png"
              },
              {
                text: ' ثانيا: ستظهر لك قائمة الخدمات، اضغط على "متجر مجيب"',
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/02.png"
              },
              {
                text:
                  "ثالثا: اختر قالب المتجر لتشاهد الفيديوهات التعليمية حول كيفية التفعيل وإدارة المنتجات والفئات",
                img:
                  "https://images-mujeeb.ams3.cdn.digitaloceanspaces.com/Mujeeb_Images/Mujeeb_Images/03.png"
              }
            ]
          },
          {
            title: "تخصيص",
            body: `خصص متجرك قبل الإنشاء`,
            inputs: [
              {
                type: "select",
                title: "أبعاد بطاقة إظهار الطبق",
                isRequired: true,
                group: 1,
                name: "aspectRatio",
                inputFor: "aspectRatio",
                default: "square"
              },
              {
                type: "select",
                title: "اسم العملة المستخدم للإظهار",
                isRequired: true,
                group: 3,
                name: "currency",
                inputFor: "currency",
                default: "SYP"
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    nextSection() {
      if (this.step === this.sectionsOfRecipe.length - 1) {
        // TODO show the user a confirmation message
        if (!this.$v.$invalid) {
          const botTemplate = `${
            this.recipe === "store" ? "المتجر" : this.recipe === "services" ? "الخدمات" : ""
          }`;
          this.$buefy.dialog.confirm({
            title: `تفعيل قالب ${botTemplate} ؟`,
            message: `هل انت متاكد من رغبتك بتفعيل قالب ${botTemplate}`,
            confirmText: "متأكد",
            cancelText: "كلا",
            type: "is-success",
            onConfirm: () => {
              // TODO make request to add the recipe to this pages
              this.loading = true;
              return this.initChatBot(this.recipe)
                .then(() => {
                  const chatbotSetting = {
                    created_at: moment().utc().format(),
                    published: true,
                    valid: true,
                    package: 'free',
                    recipe: this.recipe,
                    ...this.settings
                  }
                  return this.$store
                    .dispatch("page/setService", {
                      serviceName: "chatbot",
                      serviceData: chatbotSetting
                    })
                    .catch(error => {
                      throw { type: "store.dispatch", error: error };
                    });
                })
                .then(() => {
                  try {
                    this.$router.push({
                      name: "chatbot.settings"
                    });
                  } catch (error) {
                    throw { type: "router.push", error: error };
                  }
                })
                .catch(errObject => {
                  console.log(errObject);
                  if (errObject.type != null) {
                    console.log(errObject.error);
                    this.$buefy.toast.open({
                      duration: 3000,
                      message: "حدث خطأ ما، يرجت التواصل مع الفريق",
                      position: "is-top",
                      type: "is-danger"
                    });
                  } else {
                    if (errObject.response.data) {
                      this.$buefy.toast.open({
                        duration: 3000,
                        message: errObject.response.data.message,
                        position: "is-top",
                        type: "is-danger"
                      });
                    } else {
                      this.$buefy.toast.open({
                        duration: 3000,
                        message: "حدث خطأ ما، يرجت التواصل مع الفريق",
                        position: "is-top",
                        type: "is-danger"
                      });
                    }
                  }
                  this.step = 0;
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          });
        } else {
          this.$buefy.toast.open({
            duration: 3000,
            message: "الرجاء إدخال أسماء أزرار البطاقة الرئيسية",
            position: "is-top",
            type: "is-danger"
          });
        }
      } else {
        const element = document.getElementById("chatbot-sectionTitle");
        element.scrollIntoView();
        this.step += 1;
      }
    },
    previousSection() {
      if (this.step > 0) {
        const element = document.getElementById("chatbot-sectionTitle");
        element.scrollIntoView();
        this.step -= 1;
      } else {
        this.$emit("back");
      }
    },
    initChatBot(recipe) {
      return this.$api.customRequest({
        method: "post",
        url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/settings`,
        responseType: "json",
        data: { ...this.settings, recipe: this.recipe }
      });
    }
  },
  computed: {
    sectionsOfRecipe() {
      return this.recipes[this.recipe] || this.recipes["store"];
    },
    listTemplateHeader() {
      const itemName =
        this.recipe === "restaurant" ? "المينو" : this.recipe === "store" ? "المنتجات" : "الخدمات";
      const alternativename =
        this.recipe === "restaurant"
          ? "الأطباق"
          : this.recipe === "store"
          ? "الألبسة"
          : "الدورات التعليمية";
      return `يمكنك تعديل أسماء الأزرار في القائمة مثل تغيير اسم "${itemName}" باسم آخر مثل "${alternativename}"`;
    }
  },
  validations() {
    /**
     * make the required property TRUE if property.isRequired is true
     */
    let validation = {
      settings: {
        aspectRatio: {
          required: isRequired(true)
        },
        currency: {
          required: isRequired(true)
        },
        image: {
          required: isRequired(true)
        },
        mainMenuButtons: {
          startButton: {
            required: isRequired(true)
          }
        }
      }
    };
    const contatButtonExists = this.$getAtPath(
      ["contact", "initiated"],
      this.$store.getters["page/services"]
    );
    if (contatButtonExists) {
      validation.settings.mainMenuButtons.contactUsButton = {
        required: isRequired(true)
      };
    }
    return validation;
  }
};
</script>

<style scoped lang="scss">
.bot-initialization-dialog {
  .wizard-container {
    .section-description {
      text-align: center;
      font-size: 20px;
      line-height: 37px;
    }
    .images-examples {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-content: center;
      align-items: center;
      flex-wrap: wrap;
      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        max-width: 250px;
        text-align: center;
        // margin-bottom: 60px;
        margin: 10px 20px 60px 20px;
      }
      img {
        width: 250px;
        height: 250px;
        border-radius: 50%;
        box-shadow: 0 15px 30px 0 rgba(0, 110, 255, 0.185), 0 5px 15px 0 rgba(0, 110, 255, 0.185);
        margin-bottom: 19px;
      }
    }
    > p {
      direction: rtl;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      margin: 30px auto;
    }
    > div {
      margin-bottom: 50px;
      padding: 10px;
      > p {
        direction: rtl;
        font-size: 18px;
      }
      ul {
        margin-top: 10px;
        direction: rtl;
        li {
          margin-top: 5px;
        }
      }
    }
  }
  .wizard-control {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 100px;
    flex-direction: row-reverse;
  }
}
</style>
