<template>
  <div>
    <p
      v-if="!selectedRecipe"
      style="text-align: center; font-weight: 900; font-size: 20px; margin: 40px auto;"
    >
      اختر القالب الذي تود تفعيله
    </p>
    <div class="recipes-container" v-if="!selectedRecipe">
      <div v-for="recipe in recipes" @click="onRecipeChosen(recipe.value)" :key="recipe.value">
        <div class="recipe-card">
          <!-- <div class="recipe-icon"><i :class="recipe.icon"></i></div> -->
          <div class="recipe-icon"><font-awesome-icon :icon="recipe.icon" size="3x" /></div>
          <div class="recipe-name">{{ recipe.name }}</div>
        </div>
        <div class="recipe-description">{{ recipe.description }}</div>
      </div>
    </div>

    <initializerDialog
      v-else
      :recipe="selectedRecipe"
      @back="selectedRecipe = null"
    ></initializerDialog>
  </div>
</template>

<script>
import initializerDialog from "./initializationDialog.vue";
export default {
  name: "chatBot-initializer",
  components: {
    initializerDialog
  },
  data() {
    return {
      recipes: [
        {
          name: "متجر",
          value: "store",
          icon: "store",
          description: `يمكنك من خلال هذا القالب عرض منتجاتك وتصنيفها ضمن فئات.`
        },
        {
          name: "خدمات",
          value: "services",
          icon: "handshake",
          description: `يمكنك من خلال هذا القالب عرض خدماتك مع كافة تفاصيلها.`
        },
        {
          name: "مطعم",
          value: "restaurant",
          icon: "utensils",
          description: `يمكنك من خلال هذا القالب عرض قائمة طعام مطعمك بشكل كامل مع كافة تفاصيلها.`
        }
      ],
      selectedRecipe: null
    };
  },
  methods: {
    onRecipeChosen(recipe) {
      this.selectedRecipe = recipe;
    }
  },
  created() {
    if (this.$store.getters["page/botRecipe"] != null) {
      this.$router.push({ name: "chatbot.settings" });
    }
  }
};
</script>

<style scoped lang="scss">
.recipes-container {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  & > div {
    max-width: 250px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    margin-bottom: 50px;
  }
  .recipe-description {
    margin-top: 20px;
  }
  .recipe-card {
    display: flex;
    flex-direction: column;
    height: 190px;
    width: 145px;
    border-radius: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0, 110, 255, 0.27), 0 2px 4px 0 rgba(0, 110, 255, 0.08);
    margin: 10px;
    color: dodgerblue;
    cursor: pointer;
    flex: 0 0 auto;
    &:hover {
      box-shadow: 0 15px 30px 0 rgba(0, 110, 255, 0.185), 0 5px 15px 0 rgba(0, 110, 255, 0.185);
    }
    .recipe-icon,
    .recipe-name {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      color: inherit;
    }
    .recipe-name {
      height: 35%;
    }
    .recipe-icon {
      height: 65%;
      i {
        font-size: 50px;
      }
    }
    &.unavailable-template {
      color: rgb(161, 161, 161);
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 110, 255, 0.27), 0 2px 4px 0 rgba(0, 110, 255, 0.08);
      }
    }
  }
}
</style>
